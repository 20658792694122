import React, { useContext, useMemo } from "react";
import { AppContainer } from "./components/AppContainer";
import { GlobalStyle } from "./components/GlobalStyle";
import { FormContext, FormState } from "./context/formContext";
import { QuestionsView } from "./views/QuestionsView";
import { ContactInformationView } from "./views/ContactInformationView";
import { SuccessView } from "./views/SuccessView";

const formStateToComponent = (state: FormState): React.FC => {
  const stateComponentMap = {
    [FormState.Questions]: QuestionsView,
    [FormState.ContactInfo]: ContactInformationView,
    [FormState.Success]: SuccessView,
  };
  return stateComponentMap[state];
};

const App = (): React.ReactElement => {
  const { formState } = useContext(FormContext);
  const Component = useMemo(() => formStateToComponent(formState), [formState]);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Component />
      </AppContainer>
    </>
  );
};

export default App;
