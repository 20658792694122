import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

type TextBlockProps = React.PropsWithChildren<{
  title: string;
  autofocus?: boolean;
}>;

export const TextBlock = ({
  title,
  autofocus = false,
  children,
}: TextBlockProps): React.ReactElement => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [shouldAutofocus] = useState(() => autofocus);

  useEffect(() => {
    if (shouldAutofocus && titleRef.current) {
      titleRef.current.focus();
    }
  }, [shouldAutofocus]);

  return (
    <TextBlockContainer>
      {title && (
        <TextBlockTitle tabIndex={-1} ref={titleRef}>
          {title}
        </TextBlockTitle>
      )}
      <TextBlockContent>{children}</TextBlockContent>
    </TextBlockContainer>
  );
};

const TextBlockContainer = styled.div``;

const TextBlockTitle = styled.h3`
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 0;
`;

const TextBlockContent = styled.div`
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-bottom: 2rem;
`;
