import React, { useEffect, useRef } from "react";
import styled from "styled-components";

type AlertProps = React.PropsWithChildren<{
  label: string;
  autofocus?: boolean;
}>;

export const Alert: React.FC<AlertProps> = ({
  label,
  autofocus = false,
  children,
}: AlertProps) => {
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autofocus && alertRef.current) {
      alertRef.current.focus();
    }
  }, [autofocus]);

  return (
    <AlertContainer ref={alertRef} role="alert" aria-label={label} z-index={-1}>
      {children}
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  background: #e16868;
  border: 1px solid #b14545;
  color: #fff;
  margin: 20px 0;
  padding: 20px;
`;
