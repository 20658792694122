import React from "react";
import styled from "styled-components";
import logo from "../../assets/logo.svg";
import { visuallyHidden, focusOutline } from "../../utils/accessibility";
import Color from "../../utils/colors";
import { useLanguage } from "../../hooks/useLanguage";
import { languages } from "../../utils/config";

export const Navigation = (): React.ReactElement => {
  const [currentLanguage, setLanguage] = useLanguage();

  const changeLanguage =
    (langCode: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setLanguage(langCode);
    };

  return (
    <NavigationWrapper>
      <LogoLink href="/">
        <img src={logo} aria-hidden alt="" />
        <span>Team Finland</span>
      </LogoLink>
      <LanguageNavigation>
        {Object.entries(languages).map(([langCode, langName]) => (
          <LanguageNavigationItem
            aria-current={langCode === currentLanguage}
            key={langCode}
            href="#"
            onClick={changeLanguage(langCode)}
          >
            <span aria-hidden>{langCode}</span>
            <span>{langName}</span>
          </LanguageNavigationItem>
        ))}
      </LanguageNavigation>
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
`;

const LogoLink = styled.a`
  ${focusOutline}
  span {
    ${visuallyHidden}
  }
`;

const LanguageNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
`;

const LanguageNavigationItem = styled.a`
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) =>
    props["aria-current"] ? Color.Blue : "transparent"};

  color: ${Color.Blue};
  display: block;
  margin-right: 10px;
  outline: none;
  padding: 0 6px;
  text-decoration: none;
  text-transform: capitalize;

  ${focusOutline}

  &:last-child {
    margin-right: 0;
  }

  span {
    &:not([aria-hidden]) {
      ${visuallyHidden}
    }
  }
`;
