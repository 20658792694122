import React from "react";
import styled from "styled-components";
import { uniqueId } from "../../utils/uniqueId";

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, ...props }: CheckboxProps, ref) => {
    const id = props.id || uniqueId("checkbox_");
    const labelId = `${id}_label`;
    return (
      <CheckboxWrapper>
        <input
          {...props}
          type="checkbox"
          id={id}
          ref={ref}
          aria-labelledby={labelId}
        />
        <CheckboxLabel htmlFor={id} id={labelId}>
          {label}
        </CheckboxLabel>
      </CheckboxWrapper>
    );
  }
);

Checkbox.displayName = "Checkbox";

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const CheckboxLabel = styled.label`
  flex-grow: 1;
`;
