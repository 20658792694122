import {
  Answer,
  ContactInformation,
  ContactRequest,
  ExceptionResponse,
} from "../types/types";
import { API_URL } from "../utils/config";

export const createContactRequest = (
  answers: Answer[],
  contactInformation: ContactInformation,
  language: string
): ContactRequest => {
  return {
    language: language,
    contact: contactInformation,
    answers: answers.map((answer) => ({
      pageId: answer.pageId,
      pageNumber: answer.pageNumber,
      questionId: answer.questionId,
      userInput: answer.userInput,
    })),
  };
};

export class SubmitError extends Error {
  public response: ExceptionResponse;
  constructor(exceptionResponse: ExceptionResponse) {
    super("Submit error");
    this.response = exceptionResponse;
  }
}

export const submitForm = (
  answers: Answer[],
  contactInformation: ContactInformation,
  language: string
): Promise<void> => {
  const requestPayload = createContactRequest(
    answers,
    contactInformation,
    language
  );
  return fetch(`${API_URL}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestPayload),
  }).then(async (response) => {
    if (!response.ok) {
      throw new SubmitError((await response.json()) as ExceptionResponse);
    }
  }).catch((error) => {
    if (error instanceof SubmitError) {
      throw error;
    } else {
      throw new Error("Network error");
    }
  });
};
