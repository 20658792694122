import React, { useState } from "react";
import { uniqueId } from "../../utils/uniqueId";
import { TextFieldWrapper } from "../TextFieldWrapper";
import { useTranslation } from 'react-i18next';
import { isValidLength } from "../../utils/validation";

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  errorText?: string;
  min?: number;
  max?: number;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, errorText, min, max, ...props }: TextInputProps, ref) => {
    const [isInvalid, setIsInvalid] = useState<boolean>(Boolean(errorText));
    const [validationError, setValidationError] = useState<string>('');
    const { t } = useTranslation();

    const validate = (value: string) => {
      const invalidLength = !isValidLength(value, min, max);
      const hasMinMax = (typeof min !== 'undefined' && typeof max !== 'undefined');
      const showError = (invalidLength && hasMinMax);
      
      setIsInvalid(invalidLength || errorText !== undefined);
      setValidationError(showError ? 'invalid-length' : '');
    }

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      validate(event.target.value);

      if (props.onChange) {
        props.onChange(event);
      }
    };

    const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
      validate(event.target.value);

      if (props.onFocus) {
        props.onFocus(event);
      }
    };

    const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
      validate(event.target.value);

      if (props.onBlur) {
        props.onBlur(event);
      }
    };

    const id = props.id || uniqueId("textInput_");
    const errorTextFinal = t(errorText || validationError, { fieldname: label || '', min, max });

    return (
      <TextFieldWrapper
        id={id}
        label={label}
        required={props.required}
        errorText={isInvalid ? errorTextFinal : undefined}
      >
        <input
          {...props}
          type="text"
          ref={ref}
          min={min}
          max={max}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          aria-describedby={isInvalid ? `${id}_error` : undefined}
          className={isInvalid ? "invalid" : undefined}
        />
      </TextFieldWrapper>
    );
  }
);

TextInput.displayName = "TextInput";
