import React from "react";
import styled from "styled-components";
import { Spinner } from "../components/Spinner";

export const LoadingView = (): React.ReactElement => {
  return (
    <LoadingViewWrapper>
      <Spinner />
    </LoadingViewWrapper>
  );
};

const LoadingViewWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
