import { useCallback, useContext, useMemo, useState } from "react";
import { getPage } from "../api/pages";
import { FormContext, FormState } from "../context/formContext";
import { Answer, Page, Question } from "../types/types";
import { useMountEffect } from "./useMountEffect";

type PaginatedFormState = {
  isLoading: boolean;
  isNextPageLoading: boolean;
  isPreviousPageLoading: boolean;
  currentPage?: Page;
  currentPageNumber: number;
  totalPages: number;
  isNextPageEnabled: boolean;
  actions: {
    goToNextPage: () => void;
    goToPrevPage: () => void;
    selectAnswer: (question: Question, userInput?: string) => void;
  };
};

const TOTAL_PAGES = 3;

// Get the initial page id based on answers
const getInitialPageId = (answers: Answer[]) => {
  const lastAnswer = answers[answers.length - 1];
  return lastAnswer ? lastAnswer.nextPage || lastAnswer.pageId : 1;
};

// Get the initial page id based on answers
const getInitialPageNumber = (answers: Answer[]) => {
  return Math.max(answers.length, 1);
};

// Get the next page id based on answers and current page id
const getNextPageId = (answers: Answer[], currentPageId: number) => {
  const currentPageAnswer = answers.find((a) => a.pageId === currentPageId);
  return currentPageAnswer ? currentPageAnswer.nextPage || null : null;
};

// Get the previous page id based on answers and current page id
const getPreviousPageId = (answers: Answer[], currentPageId?: number) => {
  const currentPageAnswerIndex = answers.findIndex(
    (a) => a.pageId === currentPageId
  );
  if (currentPageAnswerIndex !== -1) {
    const previousPageAnswer = answers[currentPageAnswerIndex - 1];
    return previousPageAnswer ? previousPageAnswer.pageId : null;
  }
  const lastAnswer = answers[answers.length - 1];
  return lastAnswer ? lastAnswer.pageId : null;
};

export const usePaginatedForm = (): PaginatedFormState => {
  const { setFormState, setAnswer, answers } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isPreviousPageLoading, setIsPreviousPageLoading] =
    useState<boolean>(false);
  const [currentPageId, setCurrentPageId] = useState<number>(() =>
    getInitialPageId(answers)
  );
  const [currentPage, setCurrentPage] = useState<Page>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(() =>
    getInitialPageNumber(answers)
  );

  const fetchPage = useCallback((pageId) => {
    setIsLoading(true);
    return getPage(pageId).then((pageDTO) => {
      setCurrentPage(pageDTO);
      setIsLoading(false);
    });
  }, []);

  // Fetch the initial page when mounted
  useMountEffect(() => {
    fetchPage(currentPageId);
  });

  // Move to the next page
  const goToNextPage = useCallback(() => {
    const nextPageId = getNextPageId(answers, currentPageId);
    if (nextPageId) {
      setIsNextPageLoading(true);
      fetchPage(nextPageId).then(() => {
        const nextPageNumber = Math.min(currentPageNumber + 1, TOTAL_PAGES);
        setCurrentPageId(nextPageId);
        setCurrentPageNumber(nextPageNumber);
        setIsNextPageLoading(false);
      });
    } else {
      setFormState(FormState.ContactInfo);
    }
  }, [currentPageNumber, answers, currentPageId, setFormState, fetchPage]);

  // Move to the previous page
  const goToPrevPage = useCallback(() => {
    const prevPageId = getPreviousPageId(answers, currentPageId);
    if (prevPageId) {
      setIsPreviousPageLoading(true);
      fetchPage(prevPageId).then(() => {
        const prevPageNumber = Math.max(currentPageNumber - 1, 1);
        setCurrentPageId(prevPageId);
        setCurrentPageNumber(prevPageNumber);
        setIsPreviousPageLoading(false);
      });
    }
  }, [currentPageNumber, currentPageId, answers, fetchPage]);

  // Handle answer selection
  const selectAnswer = useCallback(
    (question: Question, userInput?: string) => {
      setAnswer({
        pageId: currentPageId,
        pageNumber: currentPageNumber,
        questionId: question.id,
        nextPage: question.nextPage,
        allowInput: question.allowInput,
        requiresRegion: question.requiresRegion,
        userInput,
      });
    },
    [currentPageId, currentPageNumber, setAnswer]
  );

  // Check if next page is enabled
  const isNextPageEnabled = useMemo(() => {
    const currentPageAnswer = answers.find((a) => a.pageId === currentPageId);
    if (!currentPageAnswer) {
      return false;
    }
    if (currentPageAnswer.allowInput) {
      const input = currentPageAnswer.userInput;
      return input ? input.trim().length > 0 : false;
    }
    return true;
  }, [answers, currentPageId]);

  return {
    isLoading,
    isNextPageLoading,
    isPreviousPageLoading,
    currentPage,
    currentPageNumber,
    totalPages: TOTAL_PAGES,
    isNextPageEnabled,
    actions: {
      goToNextPage,
      goToPrevPage,
      selectAnswer,
    },
  };
};
