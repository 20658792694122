import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parseRecipient } from "../api/recipient";
import { ExternalLink } from "../components/ExternalLink";
import { TextBlock } from "../components/TextBlock";
import { FormContext } from "../context/formContext";
import { LoadingView } from "./LoadingView";

export const SuccessView = (): React.ReactElement => {
  const { t } = useTranslation();
  const { answers } = useContext(FormContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recipient, setRecipient] = useState<string>('');

  useEffect(() => {
    const updateRecipient = async () => {
      setIsLoading(true);
      setRecipient(t(await parseRecipient(answers)));
      setIsLoading(false);
    }

    updateRecipient();
  }, [answers, t]);

  if (isLoading && recipient.length === 0) {
    return <LoadingView />;
  }

  return (
    <>
      <TextBlock title={t("thank-you")} autofocus>
        {t("thank-you-text", { recipient })}
      </TextBlock>
      <ExternalLink href={t("team-finland-url")}>
        team-finland.fi
      </ExternalLink>
    </>
  );
};
