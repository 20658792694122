import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "./useLocation";
import { pathToLanguage } from "../utils/config";

type UseLanguageFn = [string, (language: string) => void];

export const useLanguage = (): UseLanguageFn => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [language, setLanguageState] = useState(i18n.languages[0]);

  const setLanguage = useCallback((language: string) => {
    setLanguageState(language);
    i18n.changeLanguage(language);
  }, [i18n]);
  
  useEffect(() => {
    const language = pathToLanguage[location.pathname];
    
    if (language) {
      setLanguage(language);
    }

  }, [location, setLanguage]);

  return [language, setLanguage];
}
