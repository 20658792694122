import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitError, submitForm } from "../api/submit";
import { Button } from "../components/Button";
import { ButtonRow } from "../components/ButtonRow";
import { Checkbox } from "../components/Checkbox";
import { EmailInput } from "../components/EmailInput";
import { Fieldset } from "../components/Fieldset";
import { Select } from "../components/Select";
import { TextInput } from "../components/TextInput";
import { FormContext, FormState } from "../context/formContext";
import { ContactInformation, ExceptionResponse, Region } from "../types/types";
import { RECAPTCHA_KEY } from "../utils/config";
import { getContactInformationErrors, isValidEmail, isValidLength } from "../utils/validation";
import { Alert } from "../components/Alert";

export const ContactInformationView = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.languages[0];
  const {
    setFormState,
    setContactInformationField,
    contactInformation,
    contactInformationLengths,
    answers,
    regions,
  } = useContext(FormContext);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverContactInformationErrors, setServerContactInformationErrors] =
    useState<ExceptionResponse["errors"]>();
  const [hasNetworkError, setHasNetworkError] = useState(false);

  // Focus on the first errored field, after validation errors
  // have been received from the server
  useEffect(() => {
    if (serverContactInformationErrors) {
      const selector = Object.keys(serverContactInformationErrors)
        .map((name) => `input[name=${name}]`)
        .join(",");

      if (selector.length > 0) {
        const inputElement = document.querySelector(selector);
        if (inputElement) {
          (inputElement as HTMLInputElement).focus();
        }
      }
    }
  }, [serverContactInformationErrors]);

  // Handle previous button click
  const onPreviousClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    setFormState(FormState.Questions);
  };

  // Handle submit button click
  const onSubmitClick: React.MouseEventHandler = async (event) => {
    event.preventDefault();

    setServerContactInformationErrors(undefined);
    setHasNetworkError(false);
    setIsSubmitting(true);

    recaptchaRef.current
      ?.executeAsync()
      .then(() => submitForm(answers, contactInformation, currentLanguage))
      .then(() => {
        setIsSubmitting(false);
        setFormState(FormState.Success);
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error instanceof SubmitError) {
          setServerContactInformationErrors(
            getContactInformationErrors(error.response.errors)
          );
        } else {
          setHasNetworkError(true);
        }
      });
  };

  const clearServerInputValidationError = (name: keyof ContactInformation) => {
    if (serverContactInformationErrors && serverContactInformationErrors[name]) {
      const copyOfServerContactInformationErrors = { ...serverContactInformationErrors };
      delete copyOfServerContactInformationErrors[name];
      setServerContactInformationErrors(copyOfServerContactInformationErrors);
    }
  }

  // Handle text field changes
  const onFieldChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = (event) => {
    const name = event.target.name as keyof ContactInformation;
    setContactInformationField(name, event.target.value);
    clearServerInputValidationError(name);
  };

  // Handle checkbox field change
  const onCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const name = event.target.name as keyof ContactInformation;
    const { checked } = event.target;
    setContactInformationField(name, checked);
  };

  // Require region field if required by some answer or if noBusinessId checkbox is checked
  const requireRegionField = useMemo(() => {
    return (contactInformation.noBusinessId || answers.some(answer => answer.requiresRegion));
  }, [answers, contactInformation.noBusinessId]);

  // Get validation errors as a simple list of field names
  const validationErrors = useMemo(() => {
    const requiredFields: Array<keyof ContactInformation> = [
      "companyName",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
    ];

    // If region field is required, mark it here as well
    if (requireRegionField) {
      requiredFields.push("area");
    }

    // If noBusinessId has NOT been selected, require businessId and companyName fields
    if (!contactInformation.noBusinessId) {
      requiredFields.push("businessId");
    }

    // Filter out those fields that have valid input and
    // return just the list of invalid fields
    return requiredFields.filter((field) => {
      const value = contactInformation[field];
      const lengths = contactInformationLengths[field];
      const stringValue = value ? value.toString() : '';

      if (field === "email") {
        return !isValidEmail(stringValue);
      }
      return !isValidLength(stringValue, lengths?.min, lengths?.max);
    });
  }, [contactInformation, contactInformationLengths, requireRegionField]);

  const renderTextInput = (
    name: keyof ContactInformation,
    label: string,
    required = true,
  ) => {
    const Component = name === "email" ? EmailInput : TextInput;
    const length = contactInformationLengths[name];
    return (
      <Component
        label={t(label)}
        name={name}
        min={length?.min}
        max={length?.max}
        required={required}
        defaultValue={contactInformation[name] as string}
        onChange={onFieldChange}
        errorText={
          serverContactInformationErrors
            ? serverContactInformationErrors[name]
            : undefined
        }
      />
    );
  };

  const showBusinessIdField = contactInformation.noBusinessId === false;
  const showRegionField = requireRegionField;

  return (
    <>
      {hasNetworkError && (
        <Alert autofocus label={t("network-error")}>
          {t("send-failed")}
        </Alert>
      )}
      <Fieldset legend={t("company-information")} autofocus>
        <Checkbox
          label={t("no-business-id")}
          name="noBusinessId"
          defaultChecked={contactInformation.noBusinessId}
          onChange={onCheckboxChange}
        />
        {showBusinessIdField && (
          <>
            {renderTextInput("businessId", "business-id")}
          </>
        )}
        {renderTextInput("companyName", "company-name")}
        {showRegionField && (
          <Select
            label={t("area")}
            name="area"
            required
            options={[
              { label: "", value: "" },
              ...regions.map((region) => ({
                label:
                  region.translations[
                    currentLanguage as keyof Region["translations"]
                  ].name,
                value: region.region,
              })),
            ]}
            onChange={onFieldChange}
          />
        )}
      </Fieldset>
      <Fieldset legend={t("contact-information")}>
        {renderTextInput("firstName", "first-name")}
        {renderTextInput("lastName", "last-name")}
        {renderTextInput("email", "email-address")}
        {renderTextInput("phoneNumber", "phone-number")}
      </Fieldset>
      <ButtonRow>
        <Button
          disabled={validationErrors.length > 0}
          variant="primary"
          onClick={onSubmitClick}
          loadingText={t("sending")}
          isLoading={isSubmitting}
        >
          {t("send")}
        </Button>
        <Button variant="secondary" onClick={onPreviousClick}>
          {t("previous")}
        </Button>
      </ButtonRow>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={`${RECAPTCHA_KEY}`}
        size="invisible"
      />
    </>
  );
};
