import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { ButtonRow } from "../components/ButtonRow";
import { ExternalLink, createStyledIcon } from "../components/ExternalLink";
import { Fieldset } from "../components/Fieldset";
import { RadioButton } from "../components/RadioButton";
import { Steps } from "../components/Steps";
import { TextBlock } from "../components/TextBlock";
import { FormContext } from "../context/formContext";
import { usePaginatedForm } from "../hooks/usePaginatedForm";
import { Question } from "../types/types";
import { ErrorView } from "./ErrorView";
import { LoadingView } from "./LoadingView";
import { ReactComponent as ExternalIcon } from "../assets/external.svg";

export const QuestionsView = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.languages[0] as keyof Question["translations"];
  const {
    isLoading,
    isNextPageLoading,
    isPreviousPageLoading,
    currentPage,
    currentPageNumber,
    totalPages,
    isNextPageEnabled,
    actions: { goToNextPage, goToPrevPage, selectAnswer },
  } = usePaginatedForm();

  const { answers } = useContext(FormContext);

  const onFieldChange = useCallback(
    (question: Question) =>
      (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        additionalInput?: string
      ) => {
        selectAnswer(question, additionalInput);
      },
    [selectAnswer]
  );

  const renderQuestion = useCallback(
    (question: Question) => {
      if (currentPage) {
        const name = `page-${currentPage.id}`;
        const { title, description, userInputLabel } =
          question.translations[currentLanguage];
        const answer = answers.find(
          (answer) => answer.questionId === question.id
        );
        return (
          <RadioButton
            key={question.id}
            name={name}
            label={title}
            value={question.id}
            onChange={onFieldChange(question)}
            checked={answer ? true : false}
            requireUserInput={question.allowInput}
            userInputLabel={userInputLabel}
            userInputDefaultValue={answer ? answer.userInput : undefined}
          >
            {description && <p>{description}</p>}
          </RadioButton>
        );
      }
    },
    [currentPage, answers, currentLanguage, onFieldChange]
  );

  // If there's already some answered questions,
  // autofocus the first fieldset on page
  const shouldAutofocus = answers.length > 0;

  // Show top content on the first page
  const showTopContent = currentPageNumber === 1;

  if (isLoading && !currentPage) {
    return <LoadingView />;
  }

  if (!currentPage) {
    return <ErrorView />;
  }

  return (
    <>
      {showTopContent && (
        <>
          <TextBlock title={t("contact-form")}>
            {t("contact-form-text")}
          </TextBlock>
          <ExternalLink href={t("service-information-link")} IconType={StyledExternalIcon}>
            {t("service-information")}
          </ExternalLink>
        </>
      )}
      <Fieldset
        legend={currentPage.translations[currentLanguage].title}
        id={`questions-${currentPage.id}`}
        autofocus={shouldAutofocus}
      >
        {currentPage.questions.map(renderQuestion)}
      </Fieldset>
      <Steps currentPageNumber={currentPageNumber} totalPages={totalPages} />
      <ButtonRow>
        <Button
          variant="primary"
          onClick={goToNextPage}
          disabled={isNextPageEnabled === false}
          isLoading={isNextPageLoading}
          loadingText={t("loading-next-page")}
        >
          {t("next")}
        </Button>
        <Button
          variant="secondary"
          onClick={goToPrevPage}
          style={{ display: currentPageNumber > 1 ? undefined : "none" }}
          isLoading={isPreviousPageLoading}
          loadingText={t("loading-previous-page")}
        >
          {t("previous")}
        </Button>
      </ButtonRow>
    </>
  );
};

const StyledExternalIcon = createStyledIcon(ExternalIcon);