import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

type ExternalLinkProps = React.PropsWithChildren<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    IconType?: AnyStyledComponent
  }
>;

export const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  ExternalLinkProps
>(({ children, IconType, ...props }: ExternalLinkProps, ref) => {
  return (
    <a target="_blank" rel="noopener" {...props} ref={ref}>
      {children ? children : null}
      {IconType ? <IconType /> : null}
    </a>
  );
});

ExternalLink.displayName = "ExternalLink";

export const createStyledIcon = (
  Icon: (() => JSX.Element) | React.FunctionComponent
): AnyStyledComponent => styled(Icon)`
  vertical-align: middle;
  width: 0.8em;
  height: auto;
  margin-left: 0.5rem;
`;
