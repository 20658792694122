import { createGlobalStyle } from "styled-components";
import { reset } from "./normalize.css";
import Color from "../../utils/colors";
import { focusOutline } from "../../utils/accessibility";

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'finlandica';
    src: url('/fonts/finlandica-boldWeb.woff2') format('woff2'),
         url('/fonts/finlandica-boldWeb.woff') format('woff'),
         url('/fonts/finlandica-boldWeb.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'finlandica';
    src: url('/fonts/finlandica-bolditalicWeb.woff2') format('woff2'),
        url('/fonts/finlandica-bolditalicWeb.woff') format('woff'),
        url('/fonts/finlandica-bolditalicWeb.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'finlandica';
    src: url('/fonts/finlandica-italicWeb.woff2') format('woff2'),
        url('/fonts/finlandica-italicWeb.woff') format('woff'),
        url('/fonts/finlandica-italicWeb.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'finlandica';
    src: url('/fonts/finlandica-regularWeb.woff2') format('woff2'),
        url('/fonts/finlandica-regularWeb.woff') format('woff'),
        url('/fonts/finlandica-regularWeb.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: Finlandica, sans-serif;
    font-size: 1.6rem;
    background-color: ${Color.Grey};
  }

  html,
  body,
  #root {
    height: 100%;
  }

  a {
    ${focusOutline}
    color: ${Color.Blue};
  }
`;
