import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../utils/validation";
import { TextInput, TextInputProps } from "../TextInput";

export const EmailInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props: TextInputProps, ref) => {
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const { t } = useTranslation();

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      const isValid = isValidEmail(event.target.value);
      if (isInvalid && isValid) {
        setIsInvalid(false);
      }
      if (props.onChange) {
        props.onChange(event);
      }
    };

    const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
      setIsInvalid(!isValidEmail(event.target.value) || props.errorText !== undefined);

      if (props.onFocus) {
        props.onFocus(event);
      }
    };

    const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
      setIsInvalid(!isValidEmail(event.target.value) || props.errorText !== undefined);
      if (props.onBlur) {
        props.onBlur(event);
      }
    };

    const { errorText = t("invalid-email-address") } = props;

    return (
      <TextInput
        {...props}
        ref={ref}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        errorText={isInvalid ? errorText : undefined}
      />
    );
  }
);

EmailInput.displayName = "EmailInput";
