import { useEffect, useState } from "react";

export const useLocation = (): Location => {
  const [location, setLocation] = useState(window.location);
  const handlePopStateEvent = () => setLocation(window.location);

  useEffect(() => {
    window.addEventListener("popstate", handlePopStateEvent);
    return () => window.removeEventListener("popstate", handlePopStateEvent);
  }, []);

  return location;
}
